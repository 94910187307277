<template>
  <div>
    <SubNav main-route="meta" section="indicator" title="Create Indicator(s)" />
    <div class="container-fluid pb-1">
      <FileUpload
        :handle-file-selected="handleFileSelected"
        :handle-url-submitted="handleURLsubmitted"
      />
    </div>
  </div>
</template>

<script>
import SubNav from "../../../components/NavigationSubNav.vue";
import FileUpload from "../../../components/FileUpload.vue";

import UploadResource from "../../../api/fileResource";

const uploadResource = new UploadResource();

export default {
  name: "CreateIndicator",
  components: {
    SubNav,
    FileUpload,
  },
  methods: {
    /**
     *
     */
    async handleFileSelected({ file }) {
      return new Promise((resolve, reject) => {
        const formData = new FormData();
        formData.append("file", file);
        this.$progress.start();
        uploadResource
          .create(formData)
          .then((response) => {
            this.$progress.done();
            resolve(response);
          })
          .catch((error) => {
            reject(error);
            this.$progress.done();
          });
      });
    },
    /**
     *
     */
    async handleURLsubmitted(fileURL) {
      this.$progress.start();
      uploadResource
        .store({ file: fileURL })
        .then((response) => {
          console.log(response);
          this.$progress.start();
        })
        .catch((error) => {
          console.log(error);
          this.$progress.start();
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
